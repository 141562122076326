import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  PropsWithChildren,
} from 'react';

import { gql, useApolloClient } from '@apollo/client';

import 'iframe-resizer/js/iframeResizer.contentWindow';

export type WidgetServiceContextProps = {
  sendMessage: (message: any, targetOrigin?: string) => void;
};

const WidgetServiceContext = createContext<
  WidgetServiceContextProps | undefined
>(undefined);

export const useWidgetServiceContext = (): WidgetServiceContextProps => {
  const context = useContext(WidgetServiceContext);
  if (!context) {
    throw new Error('useWidgetServiceContext must be inside a WidgetService');
  }
  return context;
};

export const WidgetService = ({ children }: PropsWithChildren) => {
  const [isReady, setIsReady] = useState(false);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.iFrameResizer = {
        onMessage: (message: any) => {
          if (message?.type === 'WIDGET_UPDATE') {
            const widget = message.payload;

            apolloClient.cache.writeFragment({
              id: `${widget.__typename}:${widget.id}`,
              fragment: gql`
                fragment UpdateWidget on widgets_widget {
                  category
                  title
                  name
                  widget_teams(order_by: { team: { rank: asc } }) {
                    id
                    team_detail {
                      id
                      team_id
                      name_in_club
                    }
                  }
                }
              `,
              data: widget,
            });
          }
        },
        onReady: () => {
          setIsReady(true);
        },
      };
    }
  }, []);

  const sendMessage = useCallback(
    (message: any, targetOrigin?: string): void => {
      if (isReady) {
        window.parentIFrame.sendMessage(message, targetOrigin);
      }
    },
    [isReady]
  );

  return (
    <WidgetServiceContext.Provider value={{ sendMessage }}>
      {children}
    </WidgetServiceContext.Provider>
  );
};
