import { appWithTheme } from '@scorenco/components';
import { ApolloService, SentryService } from '@scorenco/core';
import { AppPropsWithLayout, AppLayout, NextI18nService } from '@scorenco/next';

import { WidgetService } from '../components';
import { WidgetsLayout } from '../layouts';

// Initialisation des services externes

WidgetsLayout.init({
  i18n: {
    i18n: {
      defaultLocale: 'fr',
      locales: ['fr', 'de'],
    },
  },
  router: {},
});

const App = (appProps: AppPropsWithLayout) => {
  const { pageProps } = appProps;

  return (
    <SentryService>
      <ApolloService initialApolloState={pageProps.initialApolloState}>
        <WidgetService>
          <AppLayout {...appProps} />
        </WidgetService>
      </ApolloService>
    </SentryService>
  );
};

export default appWithTheme(NextI18nService.appEnhancer(App));
