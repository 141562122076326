import {
  BaseLayout,
  GetInitialPropsWithPrevious,
  NextI18nService,
  NextRouterService,
} from '@scorenco/next';
import { GetServerSideProps, GetStaticProps } from 'next';

type WidgetsLayoutOptions = {
  i18n?: Parameters<typeof NextI18nService.getInitalPropsFn>['0'];
};

export const WidgetsLayout = {
  ...BaseLayout,
  init: (options: {
    i18n: Parameters<typeof NextI18nService.init>[0];
    router: Parameters<typeof NextRouterService.init>[0];
  }) => {
    NextI18nService.init(options.i18n);
    NextRouterService.init(options.router);
  },
  buildGetInitialProps: (
    options?: WidgetsLayoutOptions,
    list?: GetInitialPropsWithPrevious[]
  ) =>
    BaseLayout.buildGetInitialProps([
      NextI18nService.getInitalPropsFn(options?.i18n),
      ...(list || []),
    ]),
  buildGetServerSideProps: (
    options?: WidgetsLayoutOptions,
    list?: GetServerSideProps[]
  ) =>
    BaseLayout.buildGetServerSideProps([
      NextI18nService.getServerSidePropsFn(options?.i18n),
      ...(list || []),
    ]),
  buildGetStaticProps: (
    options?: WidgetsLayoutOptions,
    list?: GetStaticProps[]
  ) =>
    BaseLayout.buildGetStaticProps([
      NextI18nService.getStaticPropsFn(options?.i18n),
      ...(list || []),
    ]),
};
